@font-face {
  font-family: "Bold";
  src: local("GraphikLCG-Bold"),
    url("./fonts/GraphikLCG-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("GraphikLCG-Medium"),
    url("./fonts/GraphikLCG-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src: local("GraphikLCG-Regular"),
    url("./fonts/GraphikLCG-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Semibold";
  src: local("GraphikLCG-Semibold"),
    url("./fonts/GraphikLCG-Semibold.ttf") format("truetype");
}

.h100 {
    height: calc(100vh - 64px)
}

.brand-color {
    color: #FF3300;
}

.card {
    background: white;
    border-radius: 10px;
    padding: 40px;
}


.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chat-container {
    box-shadow: 10px 10px;
    max-width: 500px;
    max-height: 500px;
    border: 1px solid
}

.chat {
    padding: 10px;
    width: 400px;
    height: 400px;
    background-color: rgb(77, 168, 253, 0.6);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.chat::-webkit-scrollbar {
    display: none;
    /* Chrome */
}

.input-chat-container {
    padding: 10px;
    height: auto;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    background-color: rgb(53, 253, 220);
}

.input-chat {
    width: 100%;
    min-height: 25px;
    outline: none;
    border: 1px solid black;
}

.submit-chat {
    max-width: 50px;
    background-color: rgb(0, 55, 128);
    outline: none;
    border: 1px solid rgb(0, 55, 128);
    cursor: pointer;
    margin-left: 5px;
    color: white
}

.submit-chat:hover {
    width: 50px;
    background-color: rgb(16, 108, 230);
}

.my-message-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.my-message {
    padding: 5px;
    width: fit-content;
    min-width: 100px;
    max-width: 300px;
    background-color: rgb(77, 253, 215);
    border: 1px solid black;
    box-shadow: 5px 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.another-message-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.another-message {
    max-width: 300px;
    padding: 5px;
    width: fit-content;
    background-color: rgb(212, 253, 77);
    border: 1px solid black;
    box-shadow: 5px 5px;
    margin-top: 10px;
    margin-bottom: 10px;

}

.client {
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.message {
    margin-top: 3px;
    margin-bottom: 0px;
}

.title-xs {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 150% */
    text-transform: uppercase;
}

.title-s {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
}

.title-md {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
}

.title-l {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
    text-transform: uppercase;
}

.title-xl {
    font-size: 42px;
    font-style: normal;
    font-family: Bold, serif;
    font-weight: 700;
    line-height: 52px;
    text-transform: uppercase;
}

.text-md {
    font-size: 12px;
    font-style: normal;
    font-family: Regular, serif;
    font-weight: 500;
    line-height: 18px; /* 150% */
}

.description-md {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    color: #C4C7CF;
}

.ant-checkbox .ant-checkbox-inner {
    height: 15px;
    width: 15px;
}

.ant-input {
    border-color: #d9d9d9 !important
}

.ant-input:hover {
    border-color: #ff3300 !important
}

input.ant-checkbox-input {
    height: 10px;
    width: 10px;
    margin: 0;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
    height: 10px;
    width: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    height: 8px;
    width: 6px;
}

.ant-form-item .ant-form-item-control-input {
    min-height: 0;
}

.big-button {
    height: 70px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 130% */
}

.medium-button {
    height: 40px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 150% */
}

.small-button {
    height: 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 175% */
}

.ant-modal {
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    padding: 0;
}

.ant-modal .ant-modal-content {
    background-color: #F5F5F5;
    border-radius: 0;
    width: 100%;
    height: 100%;
}

.ant-modal-root .ant-modal-wrap {
    overflow: unset;
}

::-webkit-scrollbar {
    display: none;
}