@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    height: 100%;
    background: #f5f5f5;
}

body {
    height: 100%;
    margin: 0;
}

p {
    margin: 0;
}

div#root {
    height: 100%;
}

div.app {
    height: 100%;
}
